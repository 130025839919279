//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import CityListTable from '@/modules/city/components/city-list-table.vue';
import { CityPermissions } from '@/modules/city/city-permissions';

export default {
  name: 'app-city-list-page',

  components: {
    [CityListTable.name]: CityListTable,
    // [CityListToolbar.name]: CityListToolbar,
    // [CityListFilter.name]: CityListFilter,
    // [CityFilterPage.name]: CityFilterPage,
  },

  data() {
    return {
      vDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToCreate() {
      return new CityPermissions(this.currentUser).create;
    },
  },

  methods: {
    ...mapActions({

    }),
    closeDialog(){
      this.vDialog = false;
    },
    openDialog(){
      this.vDialog = true
    },
  },
};
